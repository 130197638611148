import React, { useState, useEffect, useRef } from 'react';
import {
    Drawer, Dialog, Button, toast, Notification,
} from 'components/ui/index';
import ReactCrop, { PixelCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { _enviarFotoPerfil } from 'services/UsuarioService';
import { _fbUploadFile2, _uploadLogoClinica } from 'services/FirebaseService';
import EditarCancelarBotoes from '../Buttons/EditarCancelarBotoes';

const TO_RADIANS = Math.PI / 180;

const uploadTypes = {
    perfil: _fbUploadFile2,
    logo: _uploadLogoClinica,
};

const CropDownloadImage = ({
    isOpen, onClose, filename, type = 'perfil',
}) => {
    async function canvasPreview(image, canvas, crop, scale = 1, rotate = 0) {
        if (type === 'logo') {
            const blob = await fetch(image.src)
                .then((res) => res.blob())
                .then((res) => res);

            if (blob) {
                const file = new File([blob], 'fileName.jpg', { type: 'image/jpeg' });
                uploadTypes[type](file, `${filename}.jpg`, onClose);
                // _fbUploadFile2(file, `${filename}.jpg`, onClose);
            } else {
                toast.push(
                    <Notification
                        title="Selecione uma foto para ser enviada"
                        type="warning"
                    />,
                    {
                        placement: 'top-center',
                    },
                );
            }
            return;
        }

        const ctx = canvas.getContext('2d');

        if (!ctx) {
            throw new Error('No 2d context');
        }

        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        // devicePixelRatio slightly increases sharpness on retina devices
        // at the expense of slightly slower render times and needing to
        // size the image back down if you want to download/upload and be
        // true to the images natural size.
        const pixelRatio = 1;

        canvas.width = Math.floor(crop.width * scaleX * pixelRatio);
        canvas.height = Math.floor(crop.height * scaleY * pixelRatio);

        // canvas.width = image.width
        // canvas.height = image.height

        ctx.scale(pixelRatio, pixelRatio);
        ctx.imageSmoothingQuality = 'high';

        const cropX = crop.x * scaleX;
        const cropY = crop.y * scaleY;

        ctx.drawImage(
            image,
            cropX,
            cropY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            canvas.width,
            canvas.height,
        );

        canvas.toBlob((blob) => {
            if (blob) {
                const file = new File([blob], 'fileName.jpg', { type: 'image/jpeg' });
                uploadTypes[type](file, `${filename}.jpg`, onClose);
                // _fbUploadFile2(file, `${filename}.jpg`, onClose);
            } else {
                toast.push(
                    <Notification
                        title="Selecione uma porção da foto para ser enviada"
                        type="warning"
                    />,
                    {
                        placement: 'top-center',
                    },
                );
            }
        }, 'image/jpeg');
    }

    const [crop, setCrop] = useState({
        unit: 'px', // Can be 'px' or '%'
        x: 0,
        y: 0,
        width: 80,
        height: 80,
    });
    const [imgSrc, setImgSrc] = useState('');
    const [scale, setScale] = useState(1);
    const [rotate, setRotate] = useState(0);
    const [completedCrop, setCompletedCrop] = useState({});

    const imgRef = useRef();
    const previewCanvasRef = useRef();
    const blobUrlRef = useRef('');

    async function onPreview() {
        const image = imgRef.current;
        const previewCanvas = previewCanvasRef.current;
        canvasPreview(image, previewCanvas, completedCrop, scale, rotate);
    }

    async function onDownloadCropClick() {
        const image = imgRef.current;
        const previewCanvas = previewCanvasRef.current;
        canvasPreview(image, previewCanvas, completedCrop, scale, rotate);
    }

    const selecionarImagem = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            // setCrop(undefined) // Makes crop preview update between images.
            const reader = new FileReader();
            reader.addEventListener('load', () => setImgSrc(reader.result?.toString() || ''));
            reader.readAsDataURL(e.target.files[0]);
        }
    };

    function onImageLoad(e) {
        if (aspect) {
            const { width, height } = e.currentTarget;
            setCrop(centerAspectCrop(width, height, aspect));
        }
    }

    function setarCrop(x, y) {
    // console.log(x);
    // console.log(y);
        setCrop(x);
    }

    function setarCompletedCrop(x, y) {
    // console.log(x);
    // console.log(y);
        setCompletedCrop(x);
    }

    return (
        <>
            <Drawer
                isOpen={isOpen}
                onClose={() => onClose()}
                onRequestClose={() => onClose()}
                placement={window.innerWidth < 768 ? 'bottom' : 'right'}
                title="Carregar Foto"
                height={450}
            >
                <label htmlFor="files" className="border-2 p-2 cursor-pointer">
                    Selecione a imagem
                </label>
                <input
                    className="invisible"
                    id="files"
                    type="file"
                    accept="image/*"
                    onChange={selecionarImagem}
                />

                {type === 'perfil' && (
                    <>
                        <ReactCrop
                            crop={crop}
                            onChange={(c, p) => setarCrop(c, p)}
                            onComplete={(c, p) => setarCompletedCrop(c, p)}
                            locked={false}
                            aspect={type === 'perfil' ? 1 : undefined}
                            circularCrop={type === 'perfil' ? true : false}
                        >
                            {/* <img className="mb-4 justify-center align-center text-center rounded-full h-40 w-40" */}
                            <img className="" src={imgSrc} ref={imgRef} alt="" />
                        </ReactCrop>

                        <canvas
                            className="invisible"
                            ref={previewCanvasRef}
                            style={{
                                border: '1px solid black',
                                objectFit: 'contain',
                                width: completedCrop.width,
                                height: completedCrop.height,
                            }}
                        />
                    </>
                )}
                {type === 'logo' && (
                    <img className="" src={imgSrc} ref={imgRef} alt="" />
                )}
                <EditarCancelarBotoes
                    closeHandler={() => onClose()}
                    checkHandler={onDownloadCropClick}
                />
            </Drawer>
        </>
    );
};

export default CropDownloadImage;
